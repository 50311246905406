import React from 'react'
import { Project } from '../../components'
import './projects.css'
import Pic_pro01 from '../../assets/project01.png'
import Pic_pro02 from '../../assets/project02.png'
import Pic_pro03 from '../../assets/project03.png'
import Pic_pro04 from '../../assets/project04.png'

const Projects = () => {
  return (
    <div
      className="portfolio__projects"
      id="projects"
    >
      <Project
        title="Foliage & Vine"
        skill="UI/UX Design&nbsp;&nbsp;Ecommerce"
        description={`Foliage and Vine is an e-commerce website dedicated to offering a wide selection of high-quality indoor plants, gardening essentials, and unique plant-related products. 
        \nAs the sole web designer and developer for this project, I played a multifaceted role in creating a seamless shopping experience for plant enthusiasts while showcasing the beauty of nature through captivating design elements.`}
        imgUrl={Pic_pro01}
        link={'https://www.foliageandvine.co.nz/'}
      />
      <Project
        title="Anna Katrina"
        skill="UI/UX Design&nbsp;&nbsp;Full-stack&nbsp;&nbsp;Ecommerce"
        description={`Anna Katrina is a dynamic e-commerce platform that caters to fashion aficionados seeking the latest trends and styles. In this project, I assumed a multifaceted role as the sole web designer and developer, orchestrating a harmonious fusion of aesthetics and functionality. 
        \nAs the designer, I meticulously crafted an interface that effortlessly captures the essence of style and elegance. From crafting intricate back-end functionality to seamless database management, I engineered a robust infrastructure ensuring efficient inventory control and order processing. To instill shopper trust, I seamlessly integrated secure payment gateways, facilitating safe and efficient transactions.`}
        imgUrl={Pic_pro02}
        link={'https://www.foliageandvine.co.nz/'}
      />
      <Project
        title="Crust & Crumb"
        skill="UI/UX Design&nbsp;&nbsp;Front-end"
        description={`Crust & Crumb is a delightful single-page bakery website, meticulously designed and crafted to celebrate the art of baking. As both the creative designer and the frontend developer for this project, I've brought the essence of the bakery to life in a digital form.
        \nWith an artistic touch, I've designed a visually enticing front-end that beautifully showcases our delectable range of baked goods. My front-end development skills ensure smooth navigation and responsiveness, creating a user-friendly environment for discovering our bakery's offerings.`}
        imgUrl={Pic_pro03}
        link={'https://crustandcrumb.netlify.app/'}
      />
      <Project
        title="Gardenia"
        skill="UI/UX Design&nbsp;&nbsp;Front-end"
        description={`Gardenia is single-page website, meticulously designed and developed, serves as a captivating landing page for showcasing their exclusive plant workshops. 
        \nAs both the designer and developer, I've ensured a seamless and informative user experience, guiding visitors effortlessly toward their green aspirations.`}
        imgUrl={Pic_pro04}
        link={'https://gardenia-yun-xu.netlify.app/'}
      />
    </div>
  )
}

export default Projects
