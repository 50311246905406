import React from 'react'
import './jumpingBalls.css'

const JumpingBalls = () => {
  return (
    <div className="portfolio__jumpingballs">
      <div class="portfolio__jumpingballs-balls">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default JumpingBalls
