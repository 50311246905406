import React from 'react'
import './contact.css'
import { motion, Variants, stagger } from 'framer-motion'

const Contact = () => {
  const textAnimate = {
    offscreen: { y: 100, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: { type: 'spring', bounce: 0.4, duration: 1 },
    },
  }

  return (
    <motion.div
      className="portfolio__contact grid"
      initial={'offscreen'}
      whileInView={'onscreen'}
      viewport={{ once: true, amount: 0.5 }}
      transition={{ staggerChildren: 0.5 }}
      id="contact"
    >
      <div className="porfolio__contact-text">
        <motion.p variants={textAnimate}>Get in touch</motion.p>
      </div>
      <div className="porfolio__contact-text">
        <motion.p variants={textAnimate}>shanexu89@hotmail.com</motion.p>
      </div>
      <div className="porfolio__contact-text">
        <motion.p variants={textAnimate}>Ph: 021 08387766</motion.p>
      </div>
    </motion.div>
  )
}

export default Contact
