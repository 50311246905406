import React from 'react'
import './about.css'
import Marquee from 'react-fast-marquee'
import logo from '../../assets/logo_large.png'
import { motion, Variants } from 'framer-motion'

const About = () => {
  const imageAnimate = {
    offscreen: { x: -100, opacity: 0 },
    onscreen: {
      x: 0,
      opacity: 1,

      transition: { type: 'spring', bounce: 0.4, duration: 1 },
    },
  }
  const textAnimate = {
    offscreen: { y: 300, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: { type: 'spring', bounce: 0.4, duration: 1 },
    },
  }
  return (
    <motion.div
      className="portfolio__about"
      initial={'offscreen'}
      whileInView={'onscreen'}
      viewport={{ once: true, amount: 0.2 }}
      transition={{ staggerChildren: 0.5 }}
      id="about"
    >
      <div className="portfolio__about-container  grid">
        <div className="portfolio__about_animate">
          <motion.img
            src={logo}
            variants={imageAnimate}
          />
        </div>
        <div className="portfolio__about-text">
          <motion.p variants={textAnimate}>
            Hi, I'm Yun Xu. I'm a passionate and results-driven full-stack
            developer based in Auckland, New Zealand. With over 7 years of
            experience in web design, I have honed my skills in creating
            visually stunning and intuitive user interfaces. However, my true
            passion lies in coding and pushing the boundaries of web
            development.
          </motion.p>
        </div>
        <div className="portfolio__about-text">
          <motion.p variants={textAnimate}>
            Aspiring to be a full-stack developer, I am driven to bridge the gap
            between design and functionality, crafting seamless and dynamic web
            experiences that captivate users. My love for coding and continuous
            learning has led me to explore various technologies and frameworks,
            allowing me to adapt quickly to new challenges.
          </motion.p>
        </div>
      </div>
      <div className="portfolio__about-skills">
        <Marquee
          direction="right"
          autoFill="true"
        >
          <div className="portfolio__marquee">
            <h1>Web Development✢</h1>
          </div>
        </Marquee>
        <Marquee autoFill="true">
          <div className="portfolio__marquee">
            <h1>UI/UX Design✴︎</h1>
          </div>
        </Marquee>
        <Marquee
          direction="right"
          autoFill="true"
        >
          <div className="portfolio__marquee">
            <h1>Software Development☆</h1>
          </div>
        </Marquee>
        <Marquee autoFill="true">
          <div className="portfolio__marquee">
            <h1>Agile Development✺</h1>
          </div>
        </Marquee>
      </div>
    </motion.div>
  )
}

export default About
