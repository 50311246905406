import React from 'react'
import './animations.css'
import { Circle, Circles, BoucingBalls, JumpingBalls } from '../../components'

const Animations = () => {
  return (
    <div className="portfolio__animations-container grid">
      <div className="portfolio__circle">
        <Circle />
      </div>
      <div className="portfolio__circle">
        <JumpingBalls />
      </div>
      <div className="portfolio__circle">
        <BoucingBalls />
      </div>
      <div>
        <Circles />
      </div>
    </div>
  )
}

export default Animations
