import React from 'react'
import './boucingBalls.css'

const BoucingBalls = () => {
  return (
    <div className="portfolio__bucingballs">
      <div class="portfolio__bucingballs-balls">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default BoucingBalls
